import React, { useState, useEffect } from 'react';
import { ChevronDown, Github, Linkedin, Mail, ExternalLink } from 'lucide-react';

const Portfolio = () => {
  const [activeSection, setActiveSection] = useState('hero');
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const totalScroll = document.documentElement.scrollHeight - window.innerHeight;
      const progress = (window.scrollY / totalScroll) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Progress Bar */}
      <div 
        className="fixed top-0 left-0 h-1 bg-blue-500 z-50 transition-all duration-300"
        style={{ width: `${scrollProgress}%` }}
      />

      {/* Hero Section */}
      <section className="h-screen flex items-center justify-center relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-900/20 to-purple-900/20" />
        <div className="text-center z-10 space-y-6">
          <h1 className="text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            Martin Li
          </h1>
          <p className="text-2xl text-gray-300">Electrical & Computer Engineer</p>
          <div className="flex justify-center space-x-4">
            <IconButton icon={<Github />} link="https://github.com/pt10160" />
            <IconButton icon={<Linkedin />} link="https://www.linkedin.com/in/zeqili3542/" />
            <IconButton icon={<Mail />} link="mailto:pt10160@gmail.com" />
          </div>
          <ChevronDown className="animate-bounce mt-12 w-8 h-8" />
        </div>
      </section>

      {/* Experience Timeline */}
      <section className="py-20 px-4">
        <h2 className="text-3xl font-bold text-center mb-16 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
          Experience
        </h2>
        <Timeline />
      </section>

      {/* Projects Grid */}
      <section className="py-20 px-4 bg-gray-900">
        <h2 className="text-3xl font-bold text-center mb-16 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
          Projects
        </h2>
        <ProjectGrid />
      </section>

      {/* Skills Cloud */}
      <section className="py-20 px-4">
        <h2 className="text-3xl font-bold text-center mb-16 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
          Skills
        </h2>
        <SkillCloud />
      </section>
    </div>
  );
};



const IconButton = ({ icon, link }) => (
  <a
    href={link || "#"}
    target="_blank"
    rel="noopener noreferrer"
    className="p-3 rounded-full bg-gray-800 hover:bg-gray-700 transition-colors"
  >
    {icon}
  </a>
);

const Timeline = () => (
  <div className="max-w-3xl mx-auto space-y-8">
    {[
      {
        title: "Embedded Engineer Internship",
        company: "Kenworth Truck Company",
        date: "Jun 2024-Sep 2024",
        description: "Led truck instrument panel testing and debugging"
      },
      {
        title: "Platform Software Engineer Intern",
        company: "GAC R&D Center",
        date: "Jul 2023-Sep 2023",
        description: "Developed ECU testing automation scripts"
      },
      {
        title: "Founder",
        company: "Yutu Model Studio",
        description: "A Retailer/Developer/Wholesaler of scale model tools"
      }
    ].map((item, index) => (
      <div key={index} className="flex group">
        <div className="w-24 text-sm text-gray-400">{item.date}</div>
        <div className="w-px bg-gray-800 relative">
          <div className="absolute w-3 h-3 bg-blue-500 rounded-full -left-1 group-hover:scale-150 transition-transform" />
        </div>
        <div className="px-8 group-hover:translate-x-2 transition-transform">
          <h3 className="text-xl font-semibold">{item.title}</h3>
          <p className="text-blue-400">{item.company}</p>
          <p className="text-gray-400 mt-2">{item.description}</p>
        </div>
      </div>
    ))}
  </div>
);

const ProjectGrid = () => (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto">
    {[
      {
        title: "Badminton Strike Recognition Wristband",
        description: "Arduino-based wearable with ML capabilities",
        tech: ["TensorFlow", "Arduino", "Python"]
      },
      {
        title: "Kinetic Bot Project",
        description: "Robot mice with environmental awareness",
        tech: ["ROS2", "Python", "Embedded"]
      },
      {
        title: "Single Camera Multi-Object Tracking Challenge",
        description: "Advanced computer vision system",
        tech: ["Python", "Ultralytics", "OpenCV"]
      },
      {
        title: "Conway's Game of Life Accelerator",
        description: "Hardware-accelerated cellular automaton",
        tech: ["SystemVerilog", "FPGA", "RTL"]
      },
      {
        title: "PACCAR E-Truck Challenge ",
        description: "Control Architecture Capstone",
        tech: ["Simulink", "Control Logics"]
      }

    ].map((project, index) => (
      <div key={index} className="group relative overflow-hidden rounded-xl bg-gradient-to-br from-blue-900/50 to-purple-900/50 p-6 hover:scale-105 transition-transform">
        <div className="space-y-4">
          <h3 className="text-xl font-semibold">{project.title}</h3>
          <p className="text-gray-400">{project.description}</p>
          <div className="flex flex-wrap gap-2">
            {project.tech.map((tech, i) => (
              <span key={i} className="px-3 py-1 text-sm bg-blue-900/50 rounded-full">
                {tech}
              </span>
            ))}
          </div>
        </div>
        <ExternalLink className="absolute top-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity" />
      </div>
    ))}
  </div>
);

const SkillCloud = () => {
  const skills = [
    'Python', 'C', 'Java', 'SystemVerilog', 'VHDL', 'ROS2', 'Arduino', 
    'TensorFlow', 'PyTorch', 'SQL', 'Linux', 'Machine Learning'
  ];

  return (
    <div className="flex flex-wrap justify-center gap-4 max-w-4xl mx-auto">
      {skills.map((skill, index) => (
        <div 
          key={index}
          className="px-4 py-2 bg-gradient-to-r from-blue-900/50 to-purple-900/50 rounded-full
                     hover:from-blue-600/50 hover:to-purple-600/50 transition-colors cursor-pointer"
        >
          {skill}
        </div>
      ))}
    </div>
  );
};

export default Portfolio;